import Modal from 'react-modal';

Modal.setAppElement('#root');
const ConfirmModal = ({ title, description, actions, customStyles = {} }) => {
	return (
		<Modal
			isOpen={true}
			onRequestClose={() => actions.close(false)}
			style={customStyles}
			contentLabel='Example Modal'>
			<div className='modal-content'>
				<h2 className='modal-title'>{title}</h2>
				<div className='modal-body'>{description}</div>
				<div className='modal-footer'>
					<button
						type='button'
						className='btn btn-primary'
						onClick={() => actions.confirm()}>
						Confirm
					</button>
					<button
						type='button'
						className='btn btn-danger'
						onClick={() => actions.close(false)}>
						Cancel
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmModal;
