import React from 'react';
import { useDispatch } from 'react-redux';
import { setDynamicData } from '../redux/userSlice';

const Logout = () => {
	const dispatch = useDispatch();
	dispatch(setDynamicData({ key: 'isLoggedIn', value: false }));
	dispatch(setDynamicData({ key: 'user', value: {} }));
	localStorage.removeItem('token');
	localStorage.removeItem('refreshToken');

	return <div className='container-fluid pb-4'></div>;
};
export default Logout;
