import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { imgPath } from '../../config';
import http from '../../http';
import { useToast } from 'use-toast-mui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
import { CiTrash, CiEdit } from 'react-icons/ci';
import { forms } from '../../util';
import Forms from '../../components/Forms';
import ConfirmModal from '../../components/ConfirmModal';

const AddMerchant = () => {
	const toast = useToast();
	const [fields, setFields] = useState([...forms.merchant.common]);
	const storeFields = [
		{ key: 'label', value: '' },
		{ key: 'location', value: '' },
		{ key: 'latlong', value: '' },
		{ key: 'contact_number', value: '' },
		{ key: 'region', value: '' },
	];
	const [storePayload, setStorePayload] = useState({
		state: true,
		payload: storeFields,
	});
	const location = useLocation();
	const navigation = useNavigate();
	let { id } = useParams();

	const form = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const paymentForm = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const getStores = useQuery({
		queryKey: ['getStores'],
		queryFn: () =>
			http.get(`merchants/stores?merchant=${location?.state?.merchant_id}`),
		retry: 0,
	});

	const { data, isLoading, refetch } = useQuery({
		queryKey: ['merchants'],
		queryFn: () => http.get(`admin/merchants/${location?.state?.merchant_id}`),
		enabled: false,
		retry: 0,
	});
	const queryClient = useQueryClient();

	const mutation = useMutation(
		(payload) =>
			id
				? http.put(`admin/merchants/${location?.state?.merchant_id}`, payload)
				: http.post(`admin/merchants`, payload),
		{
			onSuccess: (response) => {
				toast.success(response?.data);
				// navigation('/admin/accounts', {})
			},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
			onSettled: () => {
				queryClient.invalidateQueries('create');
			},
		}
	);

	const actions = {
		onSubmit: (data) => {
			const payload = new FormData();
			const objs = {};

			data?.createMerchant?.map((value) => {
				const obj = Object.entries(value)[0];
				objs[obj[0]] = obj[1];
				if (obj[1]) {
					if (typeof obj[1] === 'object') {
						if (obj[1][0]) payload.append(obj[0], obj[1][0], obj[0]);
					} else payload.append(obj[0], obj[1]);
				}
			});
			mutation.mutate(payload);
		},
	};

	const storeForm = useForm();

	const manageStore = (data) => {
		data.merchant_id = location?.state?.merchant_id;
		const url =
			storePayload?.state !== 'new'
				? `merchants/stores/${storePayload?.state}`
				: `merchants/stores`;
		if (storePayload?.state !== 'new') UpdateStore.mutate({ url, data });
		else createStore.mutate({ url, data });
	};

	const UpdateStore = useMutation(
		['createUpdateStore'],
		(payload) => http.put(payload?.url, payload?.data),
		{
			onSuccess: (response) => {
				toast.success(response?.data);
				getStores.refetch();
				storeForm?.reset();
				setStorePayload({
					state: '',
					payload: storeForm,
				});
			},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
		}
	);
	const createStore = useMutation(
		['createUpdateStore'],
		(payload) => http.post(payload?.url, payload?.data),
		{
			onSuccess: (response) => {
				toast.success(response?.data);
				getStores.refetch();
				storeForm?.reset();
				setStorePayload({
					state: '',
					payload: storeForm,
				});
			},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
		}
	);

	const createPaymentRef = useMutation(['createPaymentRef'], (payload) => http.put(`admin/orders/payment/token/${location?.state?.merchant_id}`, { ...payload }), {
		onSuccess: (response) => {
			toast.success(
				response?.data
			);
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		}, onError: (err) => {
			toast.error(
				err?.response?.data?.message || err?.response?.data || err?.message
			);
		}
	});

	useEffect(() => {
		Promise.all(
			fields
				.filter((f) => f.type === 'select' && f?.endpoint)
				?.map((field) => {
					return new Promise((resolve) => {
						if (field?.endpoint)
							http
								.get(field?.endpoint)
								.then((result) => {
									if (field.fieldName === 'merchant_id')
										resolve({
											[field?.fieldName]: result?.data?.rows?.map((m) => ({
												id: m?.merchant_id,
												value: m?.business_name,
											})),
										});
									else
										resolve({
											[field?.fieldName]: result?.data?.map((m) => ({
												id: m?.id,
												value: m?.label,
											})),
										});
								})
								.catch((err) => { });
					});
				})
		)
			.then((result) => {
				const resultList = {};
				result.map((m) => {
					resultList[Object.keys(m)[0]] = Object.values(m)[0];
				});
				const temp = fields;
				temp?.map((m, i) => {
					if (resultList?.[m.fieldName]) {
						temp[i].dataSet = resultList?.[m.fieldName];
					}
				});
				if (id) temp[16].validation = { disabled: true };

				setFields((prev) => [...temp]);
			})
			.catch((err) => { })
			.finally(() => {
				fields?.map(({ fieldName }, index) => {
					form.setValue(
						`createMerchant.${index}.${fieldName}`,
						location?.state?.[fieldName]
					);
				});
				fields?.map((keys, index) => {
					form.setValue(
						`createMerchant.${index}.${keys?.fieldName}`,
						data?.data?.[keys?.fieldName]
					);
				});
			});
	}, [data]);

	useEffect(() => {
		refetch();
	}, []);

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<h1>{id ? 'Update' : 'Add'} Merchant Account</h1>
					<nav
						className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
						aria-label='breadcrumb'>
						<ol className='breadcrumb pt-0'>
							<li className='breadcrumb-item'>
								<a href='#'>Marketplace</a>
							</li>
							<li className='breadcrumb-item'>
								<a onClick={() => navigation('/admin/accounts', {})}>
									Manage Merchants
								</a>
							</li>

							{id ? (
								<li className='breadcrumb-item active' aria-current='page'>
									#{id}
								</li>
							) : null}
						</ol>
					</nav>
					<div className='separator mb-5'></div>
				</div>
			</div>

			<div className='row mb-4'>
				<div className='col-12 mb-4'>
					<div className='card'>
						{id && (
							<div className='card-body'>
								<h6 className='mb-4'>Logo</h6>
								<div className='row social-image-row gallery'>
									<div className='col-auto'>
										<a>
											<img
												className='img-fluid border-radius'
												src={imgPath + data?.data?.logo}
												alt='thumbnail'
												crossOrigin='anonymous'
												style={{ height: 100 }}
											/>
										</a>
									</div>
								</div>
							</div>
						)}
						<div className='card-body'>
							<Forms
								form={form}
								formName='createMerchant'
								fields={[...fields]}
								actions={actions}
								submit={
									id ? 'Update Merchant Account' : 'Create Merchant Account'
								}
							/>
						</div>
					</div>
					{!data?.data?.payment_params1 &&
						<div className='card mt-4'>
							<div className='card-body'>
								<Forms
									form={paymentForm}
									formName='createPaymentRef'
									fields={[
										{
											fieldName: 'account_number',
											label: 'Mobile number eg. 0200000000',
											type: 'text',
											validation: { required: true, min: 10 },
										},
										{
											fieldName: 'bank_code',
											type: 'select',
											label: 'Mobile network',
											validation: { required: true },
											dataSet: [
												{
													value: 'MTN',
													id: "MTN",
												},
												{
													value: 'VODAFONE',
													id: "vod",
												},
												{
													value: 'AIRTELTIGO',
													id: "tgo",
												}
											],
										}
									]}
									actions={{
										onSubmit: (data) => {
											const payload = {};
											data?.createPaymentRef?.map(m => {
												const data1 = Object.entries(m);
												console.log(data1)
												payload[data1[0][0]] = data1[0][1];
											})

											if (payload?.account_number?.trim()?.length !== 10) {
												toast.info("Mobile number is invalid")
											} else {
												createPaymentRef.mutate(payload);
											}
										}
									}}
									submit={'Create Merchant Payment Reference'}
								/>
							</div>
						</div>
					}

					<div className='card mt-4'>
						<div className='card-body'>
							<nav
								className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
								aria-label='breadcrumb'>
								<ol className='breadcrumb pt-0'>
									<li className='breadcrumb-item'>
										<h6 className='mb-2'>Stores / Outlets</h6>
									</li>
									<li className='breadcrumb-item'>
										<a
											onClick={() => {
												storeForm.reset();
												setStorePayload({
													state: 'new',
													payload: storeFields,
												});
											}}>
											Create store
										</a>
									</li>
								</ol>
							</nav>

							{storePayload?.state && (
								<form
									onSubmit={storeForm?.handleSubmit(manageStore)}
									className='mb-4'>
									<div className='row'>
										{storePayload?.payload?.map((m) => (
											<div className='col'>
												<input
													type={'text'}
													key={m?.key}
													className='form-control'
													placeholder={m?.key}
													{...storeForm?.register(m?.key, { required: true })}
												/>
												{storeForm?.formState?.errors[m?.key] && (
													<small style={{ color: '#A00' }}>
														{m?.key} is required
													</small>
												)}
											</div>
										))}
										<div className='col-auto'>
											{!storeForm?.isSumitting ? (
												<button
													disabled={!storeForm?.formState?.isDirty}
													type='submit'
													className='btn btn-primary'>
													Submit
												</button>
											) : (
												<Spinner bg='#ffffff' fill='##001e31' />
											)}
										</div>
									</div>
								</form>
							)}

							<DataGrid
								columns={[
									{
										name: 'LABEL',
										selector: (row) => row?.id,
										sortable: true,
										width: '80px',
									},
									{
										name: 'LABEL',
										selector: (row) => row?.label,
										sortable: true,
									},
									{
										name: 'LOCATION',
										selector: (row) => row?.location,
										sortable: true,
									},
									{
										name: 'CONTACT NO.#',
										selector: (row) => row?.contact_number,
										sortable: true,
										width: '180px',
									},
									{
										name: 'REGION',
										selector: (row) => row?.region,
										sortable: true,
										width: '180px',
									},
									{
										name: 'ACTIONS',
										selector: (row) => (
											<div className='position-relative'>
												<a
													onClick={() => {
														setStorePayload({
															state: row?.id,
															payload: storeFields,
														});
														setTimeout(() => {
															storeFields?.map((mform) => {
																storeForm.setValue(
																	mform?.key,
																	row[mform?.key],
																	{ shouldDirty: true, shouldValidate: true }
																);
															});
														}, 100);
													}}>
													<span className=''>
														<CiEdit size={18} />
													</span>
												</a>
												<a onClick={() => { }}>
													<span className='badge'>
														<CiTrash size={18} />
													</span>
												</a>
											</div>
										),
										width: '100px',
									},
								]}
								data={getStores?.data?.data || []}
								progressPending={isLoading || false}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AddMerchant;
