import React from 'react';
import { setDynamicData } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';

function TopNavbar() {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);

	const logoutAccount = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		dispatch(setDynamicData({ key: 'isLoggedIn', value: false }));
		dispatch(setDynamicData({ key: 'user', value: {} }));
		window.location = '/';
	};

	return (
		<nav className='navbar fixed-top' style={{borderTop:(sessionStorage.getItem("megastore-admin-url") || "dev") ===
		"prod"
		  ?'10px solid #0A0':'10px solid #AA0'}}>
			<div className='d-flex align-items-center navbar-left'>
				<a href='#' className='menu-button d-none d-md-block'>
					<svg
						className='main'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 9 17'>
						<rect x='0.48' y='0.5' width='7' height='1' />
						<rect x='0.48' y='7.5' width='7' height='1' />
						<rect x='0.48' y='15.5' width='7' height='1' />
					</svg>
					<svg
						className='sub'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 18 17'>
						<rect x='1.56' y='0.5' width='16' height='1' />
						<rect x='1.56' y='7.5' width='16' height='1' />
						<rect x='1.56' y='15.5' width='16' height='1' />
					</svg>
				</a>

				<a
					href='#'
					className='menu-button-mobile d-xs-block d-sm-block d-md-none'>
					<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 17'>
						<rect x='0.5' y='0.5' width='25' height='1' />
						<rect x='0.5' y='7.5' width='25' height='1' />
						<rect x='0.5' y='15.5' width='25' height='1' />
					</svg>
				</a>

				<div className='search' data-search-path='Pages.Search.html?q='>
					<input placeholder='Search...' />
					<span className='search-icon'>
						<i className='simple-icon-magnifier'></i>
					</span>
				</div>

				{/*<a className="btn btn-sm btn-outline-primary ml-3 d-none d-md-inline-block"*/}
				{/*    href="https://1.envato.market/5kAb">&nbsp;BUY&nbsp;</a>*/}
			</div>

			<a className='navbar-logo' href='/admin'>
				<img className='d-none d-xs-block' src='../logos/logo.png' width='60' />
				<img
					className='logo-mobile d-block d-xs-none'
					src='./logo/logo.png'
					width='60'
				/>
			</a>

			<div className='navbar-right'>
				<div className='header-icons d-inline-block align-middle'>
					<div className='position-relative d-inline-block'>
						<button
							className='header-icon btn btn-empty'
							type='button'
							id='notificationButton'
							data-toggle='dropdown'
							aria-haspopup='true'
							aria-expanded='false'>
							<i className='simple-icon-bell'></i>
							<span className='count'>3</span>
						</button>
						<div
							className='dropdown-menu dropdown-menu-right mt-3 position-absolute'
							id='notificationDropdown'>
							<div className='scroll'>
								<div className='d-flex flex-row mb-3 pb-3 border-bottom'>
									<a href='#'>
										<img
											src='img/profiles/l-2.jpg'
											alt='Notification Image'
											className='img-thumbnail list-thumbnail xsmall border-0 rounded-circle'
										/>
									</a>
									<div className='pl-3'>
										<a href='#'>
											<p className='font-weight-medium mb-1'>
												Joisse Kaycee just sent a new comment!
											</p>
											<p className='text-muted mb-0 text-small'>
												09.04.2018 - 12:45
											</p>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<button
						className='header-icon btn btn-empty d-none d-sm-inline-block'
						type='button'
						id='fullScreenButton'>
						<i className='simple-icon-size-fullscreen'></i>
						<i className='simple-icon-size-actual'></i>
					</button>
				</div>

				<div className='user d-inline-block'>
					<button
						className='btn btn-empty p-0'
						type='button'
						data-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded='false'>
						<span className='name'>{user.name}</span>
						<span>
							{/* {(profilePic !== '') &&
                                <img alt="Profile Picture" src="img/profiles/l-1.jpg" />
                            } */}
							---
						</span>
					</button>

					<div className='dropdown-menu dropdown-menu-right mt-3'>
						{/* <a className="dropdown-item" href="#">Account</a> */}
						<a
							className='dropdown-item'
							href='#'
							onClick={() => logoutAccount()}>
							Sign out
						</a>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default TopNavbar;
