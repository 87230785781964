import { camelCased } from '../util';
import Spinner from './Spinner';
import { useEffect, useState } from 'react';
import { CiTrash } from 'react-icons/ci';

const Forms = ({ fields, form, actions, formName, submit = undefined }) => {
	const [arrFields, setArrFields] = useState({});

	useEffect(() => {
		fields
			?.filter((f) => f.type === 'array')
			?.map((m) => {
				setArrFields((prev) => ({
					...prev,
					[m.fieldName]: { dataSet: m?.dataSet || [] },
				}));
			});
	}, [fields]);

	const { errors, isDirty, isSumitting } = form.formState;
	const formType = (field, index) => {
		if (field?.type === 'select') {
			return (
				<select
					type={field.type || 'text'}
					className='form-control'
					key={field.fieldName}
					{...form.register(`${formName}.${index}.${field.fieldName}`, {
						...field.validation,
					})}>
					<option disabled value="">Select {field?.label || camelCased(field.fieldName)}</option>
					{field?.dataSet?.map((option, i) => (
						<option key={`select${field.fieldName}${i}`} value={option?.id}>
							{option?.value}
						</option>
					))}
				</select>
			);
		} else if (field?.type === 'textarea') {
			return (
				<textarea
					type={field.type || 'text'}
					className='form-control'
					key={field.fieldName}
					{...form.register(`${formName}.${index}.${field.fieldName}`, {
						...field.validation,
					})}
					style={{ height: 120 }}></textarea>
			);
		} else if (field?.type === 'array') {
			return (
				<div>
					<form>
						<div className='row'>
							{field.fields.map((m, i) => (
								<div className={`col${!i ? '' : '-3'}`}>
									<input
										type={field.type || 'text'}
										className='form-control'
										key={`subformArray${m}`}
										placeholder={m}
										value={`${arrFields[field?.fieldName]?.[m] || ''}`}
										onChange={(e) => {
											setArrFields((prev) => ({
												...prev,
												[field?.fieldName]: {
													...prev[field?.fieldName],
													[m]: e.target.value,
												},
											}));
										}}
									/>
								</div>
							))}
							<div className='col-auto'>
								<button
									type='button'
									className='btn btn-primary'
									onClick={() => {
										const prevData =
											JSON.parse(
												form.getValues()?.[formName]?.[index]?.[
												field.fieldName
												] || '[]'
											) || [];
										prevData.push(arrFields[field?.fieldName]);
										form.setValue(
											`${formName}.${index}.${field.fieldName}`,
											JSON.stringify(prevData),
											{ shouldDirty: true }
										);
										setArrFields((prev) => ({
											...prev,
											[field?.fieldName]: { dataSet: prevData },
										}));
									}}>
									Add
								</button>
							</div>
						</div>
					</form>
					<div className='mx-1 mt-4 row'>
						{(arrFields?.[field.fieldName]?.dataSet || [])?.map((m, i) => (
							<div className='col-auto mr-4 mt-4'>
								<button type='button' class='btn btn-light position-relative'>
									{m.label}
									<span
										style={{ top: -10, fontSize: 10 }}
										className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark text-light'>
										GHS {m.price}
									</span>
								</button>
								<span
									className='position-absolute '
									style={{ fontSize: 24, right: -20, top: 10 }}>
									<a
										onClick={async () => {
											if (m?.id) await actions?.deleteVariant(m?.id);

											const newData = arrFields?.[
												field.fieldName
											]?.dataSet?.filter((f, ii) => ii !== i);
											form.setValue(
												`${formName}.${index}.${field.fieldName}`,
												JSON.stringify(newData)
											);
											setArrFields((prev) => ({
												...prev,
												[field?.fieldName]: { dataSet: newData },
											}));
										}}>
										<CiTrash />
									</a>
								</span>
							</div>
						))}
					</div>
				</div>
			);
		}
		return (
			<input
				type={field.type || 'text'}
				className='form-control'
				key={field.fieldName}
				{...form.register(`${formName}.${index}.${field.fieldName}`, {
					...field.validation,
				})}
			/>
		);
	};

	return (
		<form onSubmit={form.handleSubmit(actions?.onSubmit)}>
			<div className='row' method='post'>
				{fields?.map((field, index) => {
					const header = field?.header ? (
						<div className='col-12 mt-4'>
							<h6 className='mb-2'>{field?.header}</h6>
						</div>
					) : null;
					return (
						<>
							{header}
							<div
								className={field?.col ? field?.col : 'col-6'}
								key={field.fieldName}>
								<div className='form-group'>
									<label className=''>
										{field?.label || camelCased(field.fieldName)}
									</label>
									{formType(field, index)}
									{errors?.[formName]?.[index]?.[field.fieldName] && (
										<div className='invalid-feedback d-block'>
											{errors?.username?.message ||
												`${camelCased(field.fieldName)} is required`}
										</div>
									)}
								</div>
							</div>
						</>
					);
				})}

				<div className='col-12'>
					{!isSumitting ? (
						<button
							disabled={!isDirty}
							type='submit'
							className='btn btn-primary'>
							{submit ? submit : 'Submit'}
						</button>
					) : (
						<Spinner bg='#ffffff' fill='##001e31' />
					)}
				</div>
			</div>
		</form>
	);
};
export default Forms;
