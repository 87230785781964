import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
	name: 'user',
	initialState: {
		user: {},
		isLoggedIn: false,
		accesses: [],
	},
	reducers: {
		setDynamicData: (state, action) => {
			state[action.payload.key] = action.payload.value;
		},
	},
});

export const { setDynamicData } = userSlice.actions;
export default userSlice.reducer;
