import React from 'react';
import { Outlet } from 'react-router';
import TopNavbar from './TopNavbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Layout = () => {
	return (
		<div id='app-container' className='menu-default show-spinner'>
			<TopNavbar />
			<Sidebar />
			<main>
				<Outlet />
			</main>
			<Footer />
		</div>
	);
};
export default Layout;
