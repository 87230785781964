import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { imgPath } from '../../config';
import http from '../../http';
import DataGrid from '../../components/DataGrid';

const Users = () => {
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const getUsers = async () => {
		const queryData = await http.get(
			`admin/users?page=${page}&perPage=${perPage}`
		);
		setTotal(queryData?.data?.count);
		return queryData?.data?.rows;
	};
	const { data, isLoading } = useQuery({
		queryKey: ['merchants', page],
		queryFn: () => getUsers(page),
		keepPreviousData: true,
	});

	const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page);
		setPerPage(newPerPage);
	};

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<h1>Users</h1>
					<nav
						className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
						aria-label='breadcrumb'>
						<ol className='breadcrumb pt-0'>
							<li className='breadcrumb-item'>
								<a href='/admin'>Marketplace</a>
							</li>
							<li className='breadcrumb-item'>
								<a href='/admin/accounts'>User Accounts</a>
							</li>
						</ol>
					</nav>
					<div className='separator mb-5'></div>
				</div>
			</div>

			<div className='row mb-4'>
				<div className='col-12 mb-4'>
					<div className='card'>
						<div className='card-body'>
							<DataGrid
								columns={[
									{
										name: 'IMAGE',
										selector: (row) => (
											<img
												src={imgPath + row?.image}
												width={60}
												crossOrigin='anonymous'
												className='rounded'
												alt='img'
											/>
										),
										sortable: true,
									},
									{
										name: 'NAME',
										selector: (row) => `${row?.first_name} ${row?.last_name}`,
										sortable: true,
									},
									{
										name: 'MOBILE',
										selector: (row) => `${row?.mobile}`,
										sortable: true,
									},
									{
										name: 'EMAIL',
										selector: (row) => row?.email,
										sortable: true,
									},
									{
										name: 'STATUS',
										selector: (row) => (row?.status_id ? 'Active' : 'Inactive'),
										sortable: true,
									},
									{
										name: 'ACT. TYPE',
										selector: (row) =>
											row?.merchant_id ? 'Merchant Account' : 'User Account',
										sortable: true,
									},
									{
										name: 'ACTIONS',
										selector: (row) => (
											<div>
												{/* <CiEdit size="20px" className="mr-2" onClick={() => navigation(`/admin/accounts/update/${row?.id}`, { state: row })} /> */}
											</div>
										),
										width: '100px',
									},
								]}
								data={data || []}
								progressPending={isLoading || false}
								onRowClicked={(row) => {}}
								paginationTotalRows={total}
								paginationDefaultPage={page}
								onChangeRowsPerPage={handlePerRowsChange}
								onChangePage={(page) => setPage(page)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Users;
