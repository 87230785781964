import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import http from '../http';
import useScript from '../hooks/useScript';

const Home = () => {
	const [summary, setSummary] = useState([]);
	// const dispatch = useDispatch();
	// dispatch(setDynamicData({ key: 'isLoggedIn', value: false }));

	useScript('/js/scripts.js');
	// useScript('/js/dore.scripts.landingpage.js');

	useQuery(['dashboardSummary'], () => http.get('admin/summary'), {
		onSuccess: (response) => {
			setSummary(response?.data);
		},
	});

	console.log(summary);
	const total = () =>
		summary?.users
			?.map((m) => Number(m.count))
			.reduce((prev, next) => prev + next, 0);
	const totalOrders = () =>
		summary?.orders
			?.map((m) => Number(m.count))
			.reduce((prev, next) => prev + next, 0);
	const statuses = [
		'PENDING',
		'PAID',
		'CANCELLED',
		'INPROGRESS',
		'DELIVERED',
		'DROPEDOFF',
		'PICKUP',
		'PICKEDUP',
		'DROPOFF',
		'DROPEDOFF',
	];
	const userStatuses = ['Inactive Users', 'Active Users', 'Blocked Users'];

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='mb-4 col-sm-12 col-lg-4'>
					<div className='h-100 card'>
						<div className='card-body'>
							<div className='card-title'>User Stats</div>
							{summary?.users?.map((user, index) => {
								return (
									<div className='mb-4' key={index}>
										<p className='mb-2'>
											{userStatuses[user?.status_id]}
											<span className='float-right text-muted'>
												{user?.count}/{total()}
											</span>
										</p>
										<div className='progress'>
											<div
												className='progress-bar'
												role='progressbar'
												aria-valuenow='66.66666666666666'
												aria-valuemin='0'
												aria-valuemax='100'
												style={{
													width: `${String(
														(user?.count / total()).toFixed(2) * 100
													)}%`,
												}}></div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className='mb-4 col'>
					<div className='dashboard-sq-banner justify-content-end card'>
						<div className='justify-content-end d-flex flex-column card-body'>
							<span className='badge badge-pill badge-theme-3 align-self-start mb-3'>
								GOGO
							</span>
							<p className='lead text-white'>MAGIC IS IN THE DETAILS</p>
							<p className='text-white'>Yes, it is indeed!</p>
						</div>
					</div>
				</div>
			</div>
			<div className='row'>
				{summary?.orders?.map((order, index) => (
					<div data-id='1' className='mb-4 col' key={index}>
						<div className='card'>
							<div className='p-0 position-relative card-header'>
								<div className='position-absolute handle card-icon'>
									<i className='simple-icon-shuffle'></i>
								</div>
							</div>
							<div className='d-flex justify-content-between align-items-center card-body'>
								<div className='mb-0 card-title'>
									{statuses[order?.status]} <b>({order?.count})</b>
								</div>
								<div className='progress-bar-circle'>
									<svg
										className='CircularProgressbar '
										viewBox='0 0 100 100'
										data-test-id='CircularProgressbar'>
										<path
											className='CircularProgressbar-trail'
											d='
      M 50,50
      m 0,-48
      a 48,48 0 1 1 0,96
      a 48,48 0 1 1 0,-96
    '
											strokeWidth='4'
											fillOpacity='0'
											style={{
												strokeDasharray: '301.593px, 301.593px',
												strokeDashoffset: '0px',
											}}></path>
										<path
											className='CircularProgressbar-path'
											d='
      M 50,50
      m 0,-48
      a 48,48 0 1 1 0,96
      a 48,48 0 1 1 0,-96
    '
											strokeWidth='4'
											fillOpacity='0'
											style={{
												strokeDasharray: '301.593px, 301.593px',
												strokeDashoffset: '108.573px',
											}}></path>
										<text
											className='CircularProgressbar-text'
											x='30'
											y='50'>{`${((order?.count / totalOrders()) * 100).toFixed(
											2
										)}%`}</text>
									</svg>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className='row'>
				<div className='mb-4 col-sm-12 col-md-6'>
					<div className=' dashboard-filled-line-chart card'>
						<div className='card-body'>
							<div className='float-left float-none-xs'>
								<div className='d-inline-block'>
									<h5 className='d-inline'>Website Visits</h5>
									<span className='text-muted text-small d-block'>
										Unique Visitors
									</span>
								</div>
							</div>
							<div className='btn-group float-right float-none-xs mt-2'>
								<div className='dropdown'>
									<button
										type='button'
										aria-haspopup='true'
										aria-expanded='false'
										className='btn-xs dropdown-toggle btn btn-outline-primary'>
										This Week
									</button>
									<div
										tabIndex='-1'
										role='menu'
										aria-hidden='true'
										className='dropdown-menu dropdown-menu-right'>
										<button
											type='button'
											tabIndex='0'
											role='menuitem'
											className='dropdown-item'>
											Last Week
										</button>
										<button
											type='button'
											tabIndex='0'
											role='menuitem'
											className='dropdown-item'>
											This Month
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className='chart card-body pt-0'>
							<div className='chartjs-size-monitor'>
								<div className='chartjs-size-monitor-expand'>
									<div className=''></div>
								</div>
								<div className='chartjs-size-monitor-shrink'>
									<div className=''></div>
								</div>
							</div>
							<canvas
								style={{ display: 'block', height: '195px', width: '464px' }}
								width='928'
								height='390'
								className='chartjs-render-monitor'></canvas>
						</div>
					</div>
				</div>
				<div className='mb-4 col-sm-12 col-md-6'>
					<div className='dashboard-filled-line-chart card'>
						<div className='card-body'>
							<div className='float-left float-none-xs'>
								<div className='d-inline-block'>
									<h5 className='d-inline'>Orders Stats</h5>
									<span className='text-muted text-small d-block'>
										Per Session
									</span>
								</div>
							</div>
							<div className='btn-group float-right float-none-xs mt-2'>
								<div className='dropdown'>
									<button
										type='button'
										aria-haspopup='true'
										aria-expanded='true'
										className='btn-xs dropdown-toggle btn btn-outline-secondary'>
										This Week
									</button>
									<div
										tabIndex='-1'
										role='menu'
										aria-hidden='true'
										className='dropdown-menu dropdown-menu-right'>
										<button
											type='button'
											tabIndex='0'
											role='menuitem'
											className='dropdown-item'>
											Lat Week
										</button>
										<button
											type='button'
											tabIndex='0'
											role='menuitem'
											className='dropdown-item'>
											This Month
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className='chart card-body pt-0'>
							<div className='chartjs-size-monitor'>
								<div className='chartjs-size-monitor-expand'>
									<div className=''></div>
								</div>
								<div className='chartjs-size-monitor-shrink'>
									<div className=''></div>
								</div>
							</div>
							<canvas
								style={{ display: 'block', height: '195px', width: '464px' }}
								width='928'
								height='390'
								className='chartjs-render-monitor'></canvas>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Home;
