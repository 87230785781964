import '../nopage.css';
const NoPage = () => {
	return (
		<div id='app-container' className='menu-default show-spinner'>
			<main>
				<div class='container'>
					<div class='h-100 row'>
						<div class='mx-auto my-auto col-12 col-md-4'>
							<div class=''>
								<div class='position-relative  '></div>
								<div class='form-side'>
									<a aria-current='page' class='white active' href='/'>
										<img
											className='d-none d-xs-block'
											src='../logos/cs.png'
											width='60'
											alt='img'
										/>
									</a>
									<div class='mb-4 card-title'>
										Ooops... looks like an error occurred!
									</div>
									<p class='mb-0 text-muted text-small mb-0'>Error code</p>
									<p class='display-1 font-weight-bold mb-5'>404</p>
									<a class='btn btn-primary btn-shadow btn-lg' href='/admin'>
										GO BACK HOME
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};
export default NoPage;
