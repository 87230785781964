import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useToast } from 'use-toast-mui';
import { forms } from '../../util';
import Forms from '../../components/Forms';
import { useMutation, useQuery } from '@tanstack/react-query';
import { imgPath } from '../../config';
import http from '../../http';
import { useCallback, useEffect, useState } from 'react';
import Spinner from '../../components/Spinner';

const ViewOrder = () => {
	const toast = useToast();
	const location = useLocation();
	const navigation = useNavigate();
	const [fields, setFields] = useState([...forms.order.common]);
	const [details, setDetails] = useState({});

	let { id } = useParams();
	const form = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const order = useQuery(['order', id],
		() => http.get(`orders/orders/${id}`),
		{
			onSuccess: (response) => {
				setData({ ...response?.data.orders, deliveryData: response?.data.deliveryData || {} });
			}
		}
	);


	const cancelDelivery = useMutation(['cancelDelivery'], (id) => http.put(`admin/orders/cancel/delivery/${id}`, {}), {
		onSuccess: (response) => {
			toast.success(response?.data);
			order.refetch();
		},
		onError: (err) => {
			toast.error(
				err?.response?.data?.message || err?.response?.data || err?.message
			);
		},
	})

	const createDelivery = useMutation(['createDelivery'], (id) => http.post(`admin/orders/create/delivery`, { order_id: details?.id }), {
		onSuccess: (response) => {
			toast.success(response?.data);
			order.refetch();
		},
		onError: (err) => {
			toast.error(
				err?.response?.data?.message || err?.response?.data || err?.message
			);
		},
	})

	const payMerchant = useMutation(['payMerchant'], (id) => http.put(`admin/orders/pay-merchant/${details?.id}`, {}), {
		onSuccess: (response) => {
			toast.success(response?.data);
			order.refetch();
		},
		onError: (err) => {
			toast.error(
				err?.response?.data?.message || err?.response?.data || err?.message
			);
		},
	})

	const updateMutation = useMutation(
		(payload) => http.put('admin/orders/update', payload),
		{
			onSuccess: (response) => {
				toast.success(response?.data);
				order.refetch();
			},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
		}
	);

	const deliveryMutation = useMutation(
		(payload) => http.post('admin/orders/create/delivery', payload),
		{
			onSuccess: (response) => {
				toast.success(response?.data);
				order?.refetch();
			},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
		}
	);

	useMutation((payload) => http.post(`users/delivery/status/${id}`, payload), {
		onSuccess: (response) => {
			toast.success(response?.data);
			order?.refetch();
		},
		onError: (err) => {
			toast.error(
				err?.response?.data?.message || err?.response?.data || err?.message
			);
		},
	});

	const actions = {
		onSubmit: (data) => {
			const { id } = order?.data?.data?.orders;
			updateMutation.mutate({
				order_id: id,
				status: Number(data?.viewOrder?.[17]?.status),
				merchant_comment: data?.viewOrder?.[18]?.merchant_comment,
			});
		},
		createDelivery: (data) => {
			deliveryMutation.mutate(data);
		},
	};

	if (order?.isSuccess) {
		if (order?.data?.data?.orders) {
			const orderData = {
				...order?.data?.data?.orders?.Merchant,
				...order?.data?.data?.customer,
				store_label: order?.data?.data?.store?.label,
				store_address: order?.data?.data?.store?.location,
				store_contact_number: order?.data?.data?.store?.contact_number,
				store_region: order?.data?.data?.store?.region,
				...order?.data?.data?.orders,
			};

			const temp = fields;
			if (!temp[17]?.dataSet) {
				temp[17].dataSet = order?.data?.data?.orderStatus?.map(
					({ id, label }) => ({ id: id, value: label })
				);

				if (order?.data?.data?.orders?.status === 5) {
					temp[17].validation = { disabled: true };
					temp[18].validation = { disabled: true };
				}
				setFields((prev) => [...temp]);
			}

			setTimeout(() => {
				forms.order?.common?.map((m, index) => {
					form.setValue(
						`viewOrder.${index}.${m?.fieldName}`,
						orderData?.[m?.fieldName]
					);
				});
			}, 500);
		}
	}

	useEffect(() => {
		const temp = fields;
		temp[17].dataSet = order?.data?.data?.orderStatus?.map(({ id, label }) => ({
			id: id,
			value: label,
		}));
		setFields((prev) => [...temp]);
		setData(location.state);
	}, []);

	const setData = (data) => {
		setDetails(prev => ({ ...prev, ...data }));
	};

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<h1>Order Management</h1>
					<nav
						className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
						aria-label='breadcrumb'>
						<ol className='breadcrumb pt-0'>
							<li className='breadcrumb-item'>
								<a href='#'>Marketplace</a>
							</li>
							<li className='breadcrumb-item'>
								<a onClick={() => navigation('/admin/orders', {})}>Orders</a>
							</li>

							<li className='breadcrumb-item active' aria-current='page'>
								#{id}
							</li>
						</ol>
					</nav>
					<div className='separator mb-5'></div>
				</div>
			</div>

			<div className='row mb-4'>
				<div className='col-12 mb-4'>
					<div className='card'>
						<div className='card-body'>
							<div className='row'>
								{order?.isSuccess &&
									order?.data?.data?.orderProducts?.map((m, i) => (
										<div
											class='col-sm-6 col-lg-3 col-xl-2'
											key={`pdtImages_${i}`}>
											<div class='react-contextmenu-wrapper'>
												<div class='active card'>
													<div class='position-relative'>
														<a
															aria-current='page'
															class='w-40 w-sm-100 active'
															href='/app/pages/product/image-list?p=18'>
															{m?.ProductImages.length && (
																<img
																	alt='Bebinca'
																	src={imgPath + m?.ProductImages[0]?.file_path}
																	class='card-img-top'
																	crossOrigin='anonymous'
																/>
															)}
														</a>
														<span class='position-absolute badge-top-left badge badge-secondary badge-pill'>
															GHS {m?.selling_price}
														</span>
													</div>
													<div class='card-body'>
														<div class='row'>
															<div class='col-12'>
																<div class='card-subtitle'>{m?.name}</div>
																<p class=' text-small mb-0 font-weight-bold card-text'>
																	Quantity Ordered :{' '}
																	{
																		order?.data?.data?.orders?.Items?.find(
																			(f) => f.product_id === m?.product_id
																		)?.quantity
																	}
																	x
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
						<div className='card-body'>
							<Forms
								form={form}
								formName='viewOrder'
								fields={[...(fields || [])]}
								actions={actions}
								submit={id ? 'Update Category' : 'Create Category'}
							/>
							{details?.status === 2 && (
								<div className='row mt-4 border py-4'>
									<div className='col-4'>
										{!payMerchant.isLoading ? (
											<>
												<button type='button' className={`btn btn-${details?.merchant_payment_id ? 'info' : 'primary'} mt-1`} onClick={() => payMerchant.mutate()}>
													{details?.merchant_payment_id ? `Check Merchant Payment Status` : "Pay the merchant"}
												</button>
												<br />
												{details?.merchant_payment_id && <><small>Payment ID </small>{details?.merchant_payment_id}</>}
											</>
										) : (
											<Spinner bg='#ffffff' fill='##001e31' />
										)}
										<br />
										<small>Click to send the money to the merchant.<br />
											Do this after you have called the merchant to place the order.</small>
									</div>
									{details?.paymentChannel_id === 2 && details?.delivery_options_id?.toLowerCase() ===
										'delivery' && (!details.deliveryData?.secret || details.deliveryData?.status === "CANCELLED") &&
										<div className='col-4'>

											{!createDelivery.isLoading ? (
												<button type='button' className='btn btn-primary mt-1' onClick={() => createDelivery.mutate()}>
													Request For delivery
												</button>
											) : (
												<Spinner bg='#ffffff' fill='##001e31' />
											)}<br />
											<small>Create the delivery request after paying the merchant. <br />This will raise an order with swoove or shaqexpress</small>

										</div>
									}
									{details?.delivery_options_id?.toLowerCase() ===
										'delivery' ? (
										<div className='col-4'>
											{details?.deliveryData?.secret &&
												<>
													{!cancelDelivery.isLoading ?
														<button type='button' className='btn btn-danger mt-1' onClick={() => cancelDelivery.mutate(details?.id)}>
															Cancel Delivery
														</button> :
														<Spinner bg='#ffffff' fill='##001e31' />
													}
													<br />
													<strong>
														Delivery for this order has been created and active
													</strong>
													<br />
													Delivery Status :{' '}
													<strong>{details?.deliveryData?.status}</strong>
													<br />
													Delivery code: <strong>{details?.deliveryData?.code}</strong>
													<br />
													Delivery secret: <strong>{details?.deliveryData?.secret}</strong>
												</>
											}
										</div>
									) : null}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewOrder;
