import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { imgPath } from '../../config';
import http from '../../http';
import { useToast } from 'use-toast-mui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CiTrash } from 'react-icons/ci';
import { forms } from '../../util';
import Forms from '../../components/Forms';

const AddProduct = () => {
	const toast = useToast();
	const [fields, setFeidls] = useState([...forms.products.common]);
	const [images, setImages] = useState([]);
	const [storeSelect, setStoreSelect] = useState([]);
	const [redirect, setRedirect] = useState(false);
	const location = useLocation();
	const navigation = useNavigate();
	let { id } = useParams();

	const form = useForm({
		defaultValues: {},
		mode: 'all',
	});
	const queryClient = useQueryClient();
	const watchMerchant = form.watch('createProducts.10.merchant_id');
	const mutation = useMutation(
		(payload) =>
			id
				? http.put(`products/product/${location.state?.product_id}`, payload)
				: http.post(`products/product`, payload),
		{
			onSuccess: (response) => {
				toast.success(response?.data);
				if (redirect) navigation('/admin/products', {});
			},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
			onSettled: () => {
				queryClient.invalidateQueries('create');
			},
		}
	);

	const deleteImageMutation = useMutation(
		(id) => http.delete(`products/product/image/${id}`),
		{
			onSuccess: (response) => {
				toast.success(response?.data?.message);
			},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
			onSettled: () => {
				queryClient.invalidateQueries('create');
			},
		}
	);

	useEffect(() => {
		if (images.length && id) {
			setRedirect(false);
			mutation.mutate(setPayload(location.state));
			setImages([]);
		}
	}, [images]);

	const setPayload = (data) => {
		const payload = new FormData();
		if (images.length) {
			images?.map((img) => {
				payload.append('images', img, 'images');
			});
		}
		console.log(storeSelect);
		if (storeSelect.length)
			payload.append('stores', JSON.stringify(storeSelect));

		data?.createProducts?.map((value) => {
			const obj = Object.entries(value)[0];
			if (obj[1]) {
				if (typeof obj[1] === 'object') {
					if (obj[1][0]) payload.append(obj[0], obj[1][0], obj[0]);
				} else payload.append(obj[0], !isNaN(obj[1]) ? Number(obj[1]) : obj[1]);
			} else {
				payload.append(obj[0], !isNaN(obj[1]) ? Number(obj[1]) : '');
			}
		});

		return payload;
	};

	const deleteVarientMutation = useMutation(
		(id) => http.delete(`products/product/variant/${id}`),
		{
			onSuccess: (response) => {},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
			onSettled: () => {
				queryClient.invalidateQueries('create');
			},
		}
	);

	const actions = {
		onSubmit: (data, e) => {
			e.preventDefault();
			setRedirect(true);

			mutation.mutate(setPayload(data));
		},
		deleteVariant: (data) => {
			deleteVarientMutation.mutate(data);
		},
	};

	//Get product image
	const { data, remove } = useQuery({
		queryKey: ['pdtImages'],
		queryFn: () =>
			http.get(`products/product/image/${location?.state?.product_id}`),
		enabled: !!location?.state?.product_id,
		retry: 0,
	});
	const stores = useQuery({
		queryKey: ['pdtStores', watchMerchant],
		queryFn: () => http.get(`merchants/stores?merchant=${watchMerchant}`),
		retry: 0,
		enabled: !!watchMerchant,
	});

	useEffect(() => {
		Promise.all(
			fields
				.filter((f) => f.type === 'select' && f?.endpoint)
				?.map((field) => {
					return new Promise((resolve) => {
						if (field?.endpoint)
							http
								.get(field?.endpoint)
								.then((result) => {
									if (field.fieldName === 'merchant_id')
										resolve({
											[field?.fieldName]: result?.data?.rows?.map((m) => ({
												id: m?.merchant_id,
												value: m?.business_name,
											})),
										});
									else
										resolve({
											[field?.fieldName]: result?.data?.map((m) => ({
												id: m?.id,
												value: m?.label,
											})),
										});
								})
								.catch((err) => {});
					});
				})
		)
			.then((result) => {
				const resultList = {};
				result.map((m) => {
					resultList[Object.keys(m)[0]] = Object.values(m)[0];
				});
				const temp = fields;
				temp?.map((m, i) => {
					if (resultList?.[m.fieldName]) {
						temp[i].dataSet = resultList?.[m.fieldName];
					}
				});
				setFeidls((prev) => [...temp]);
			})
			.catch((err) => {})
			.finally(() => {
				fields?.map(({ fieldName }, index) => {
					if (fieldName === 'variants')
						form.setValue(
							`createProducts.${index}.${fieldName}`,
							JSON.stringify(location?.state?.ProductVariants)
						);
					else
						form.setValue(
							`createProducts.${index}.${fieldName}`,
							location?.state?.[fieldName]
						);
				});
				const temp = fields;
				temp[15].dataSet = location?.state?.ProductVariants;
				setFeidls((prev) => [...temp]);
			});
	}, [location]);

	useEffect(() => {
		form.setValue(
			`createProducts.0.name`,
			form.getValues()?.createProducts?.[0]?.name,
			{ shouldDirty: true }
		);
	}, [storeSelect]);

	useEffect(() => {
		if (!id) {
			remove();
		}
	}, []);

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<h1>{id ? 'Update' : 'Add'} Products</h1>
					<nav
						className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
						aria-label='breadcrumb'>
						<ol className='breadcrumb pt-0'>
							<li className='breadcrumb-item'>
								<a href='#'>Marketplace</a>
							</li>
							<li className='breadcrumb-item'>
								<a onClick={() => navigation('/admin/products', {})}>
									Products
								</a>
							</li>

							{id ? (
								<li className='breadcrumb-item active' aria-current='page'>
									#{id}
								</li>
							) : (
								<li className='breadcrumb-item active' aria-current='page'>
									New Record
								</li>
							)}
							{id && (
								<li className='breadcrumb-item active' aria-current='page'>
									{location?.state?.name}
								</li>
							)}
						</ol>
					</nav>
					<div className='separator mb-5'></div>
				</div>
			</div>

			<div className='row mb-4'>
				<div className='col-12 mb-4'>
					<div className='card'>
						<div className='card-body'>
							<h6 className='mb-4'>Products Images</h6>
							<div className='row social-image-row gallery'>
								<div className='col-auto'>
									<a>
										<input
											type='file'
											style={{
												position: 'absolute',
												overflow: 'hidden',
												width: 140,
												height: 140,
												opacity: 0,
											}}
											onChange={(e) => {
												if (e.target.files.length > 0) {
													setImages((prev) => [...prev, e.target.files[0]]);
												}
											}}
										/>
										<div
											className='img-fluid border-radius text-center'
											src={''}
											alt='thumbnail'
											style={{
												paddingTop: 55,
												height: 140,
												width: 140,
												border: '1px solid #ccc',
											}}>
											Add New Image
										</div>
									</a>
								</div>
								{images?.map((img, ind) => (
									<div className='col-auto'>
										<div className='position-relative'>
											<a
												onClick={() =>
													setImages((prev) => prev.filter((f, i) => i !== ind))
												}>
												<span className='position-absolute badge-top-left badge badge-secondary badge-pill'>
													<CiTrash size={24} />
												</span>
											</a>
										</div>
										<a href='/'>
											<img
												className='img-fluid border-radius'
												src={URL.createObjectURL(img)}
												alt='thumbnail'
												crossOrigin='anonymous'
												style={{ height: 140 }}
											/>
										</a>
									</div>
								))}
								{(Array.isArray(data?.data) ? data?.data : [])?.map((img) => (
									<div className='col-auto'>
										<div className='position-relative'>
											<a onClick={() => deleteImageMutation.mutate(img?.id)}>
												<span className='position-absolute badge-top-left badge badge-secondary badge-pill'>
													<CiTrash size={24} />
												</span>
											</a>
										</div>
										<a href='/'>
											<img
												className='img-fluid border-radius'
												src={imgPath + img?.file_path}
												alt='thumbnail'
												crossOrigin='anonymous'
												style={{ height: 140 }}
											/>
										</a>
									</div>
								))}
							</div>
						</div>

						{stores?.data?.data && (
							<div className='card-body'>
								<h6 className='mb-4'>Assign Product To Your Stores/Outlets</h6>
								<div className='row'>
									<div className='col-12'>
										<div className='row'>
											<div className='col-3' style={{ cursor: 'pointer' }}>
												<div className='row p-2'>
													<div
														onClick={() => {
															setStoreSelect(
																stores?.data?.data?.map((m) => m?.id)
															);
														}}
														style={{ height: 80, marginBottom: 10 }}
														className='col border border-radius p-2'>
														<strong className='mb-0'>All stores</strong>
														<p>
															Available in all stores
															<br />
														</p>
													</div>
													<div
														onClick={() => {
															setStoreSelect([]);
														}}
														style={{ height: 80, marginBottom: 10 }}
														className='col border border-radius p-2'>
														<strong className='mb-0'>Remove All</strong>
														<p>
															Available in all stores
															<br />
														</p>
													</div>
												</div>
											</div>
											{stores?.data?.data.map((store) => (
												<div
													className='col-3'
													style={{ cursor: 'pointer' }}
													onClick={() => {
														if (storeSelect.includes(store?.id)) {
															setStoreSelect((prev) => [
																...prev.filter((f) => f !== store?.id),
															]);
														} else {
															setStoreSelect((prev) =>
																Array.from(new Set([...prev, store?.id]))
															);
														}
													}}>
													<div
														style={{
															height: 80,
															marginBottom: 10,
															background: storeSelect.includes(store?.id)
																? '#ededed'
																: '#fff',
														}}
														className='border border-radius p-2'>
														<strong className='mb-0'>{store?.label}</strong>
														<p>
															{store?.location}
															<br />
														</p>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						)}

						<div className='card-body'>
							<h6 className='mb-4'>
								{id ? 'Update' : 'Add New'} Products {id ? `#${id}` : ''}
							</h6>
							<Forms
								form={form}
								formName='createProducts'
								fields={fields}
								actions={actions}
								submit={id ? 'Update Products' : 'Create Products'}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AddProduct;
