import { imgPath } from './config';

export const forms = {
	category: {
		common: [
			{ fieldName: 'label', type: 'text', validation: { required: true } },
			{ fieldName: 'image', type: 'file', validation: { required: true } },
		],
	},
	products: {
		common: [
			{ fieldName: 'name', type: 'text', validation: { required: true } },
			{
				fieldName: 'productCategory_id',
				type: 'select',
				validation: { required: true },
				endpoint: 'products/product/categories',
			},
			{ fieldName: 'sku', type: 'text', validation: { required: true } },
			{ fieldName: 'cost_price', type: 'text', validation: { required: true } },
			{
				fieldName: 'selling_price',
				type: 'text',
				validation: { required: true },
			},
			{ fieldName: 'discount', type: 'text', validation: {} },
			{
				fieldName: 'description',
				type: 'textarea',
				validation: { required: true },
				col: 'col-12',
			},
			{ fieldName: 'barcode', type: 'text', validation: {} },
			{ fieldName: 'quantity', type: 'text', validation: {} },
			{ fieldName: 'taxable', type: 'text', validation: {} },
			{
				fieldName: 'merchant_id',
				type: 'select',
				validation: { required: true },
				endpoint: 'admin/merchants',
			},
			{
				fieldName: 'status',
				type: 'select',
				validation: { required: true },
				dataSet: [
					{
						value: 'Published',
						id: 1,
					},
					{
						value: 'Unpublished',
						id: 0,
					},
				],
			},
			{ fieldName: 'productType', type: 'text', validation: {} },
			{
				fieldName: 'promoted',
				type: 'select',
				validation: {},
				dataSet: [
					{
						value: 'Promoted',
						id: 1,
					},
					{
						value: 'Not promoted',
						id: 0,
					},
				],
			},
			{ fieldName: 'promo_text', type: 'text', validation: {} },
			{
				fieldName: 'variants',
				type: 'array',
				dataSet: [],
				fields: ['label', 'price', 'quantity'],
				col: 'col-12',
				validation: {},
				header: 'Product Variants',
			},
		],
	},
	order: {
		common: [
			{
				fieldName: 'first_name',
				type: 'text',
				validation: { disabled: true },
				header: 'Customer Details',
			},
			{ fieldName: 'last_name', type: 'text', validation: { disabled: true } },
			{ fieldName: 'email', type: 'text', validation: { disabled: true } },
			{ fieldName: 'mobile', type: 'text', validation: { disabled: true } },
			{
				fieldName: 'order_id',
				type: 'text',
				validation: { disabled: true },
				header: 'Order Details',
			},
			{ fieldName: 'subtotal', type: 'text', validation: { disabled: true } },
			{ fieldName: 'order_timestamp', type: 'text', validation: { disabled: true } },
			{ fieldName: 'total', type: 'text', validation: { disabled: true } },
			{
				fieldName: 'delivery_price',
				type: 'text',
				validation: { disabled: true },
			},
			{
				fieldName: 'comment',
				type: 'textarea',
				validation: { disabled: true },
				col: 'col-12',
			},
			{
				fieldName: 'business_name',
				type: 'text',
				validation: { disabled: true },
				header: 'Merchant & Store/Outlet Details',
			},
			{
				fieldName: 'business_mobile',
				type: 'text',
				validation: { disabled: true },
			},
			{
				fieldName: 'store_label',
				type: 'text',
				validation: { disabled: true },
			},
			{
				fieldName: 'store_address',
				type: 'text',
				validation: { disabled: true },
			},
			{
				fieldName: 'store_contact_number',
				type: 'text',
				validation: { disabled: true },
			},
			{
				fieldName: 'store_region',
				type: 'text',
				validation: { disabled: true },
			},
			{
				fieldName: 'delivery_options_id',
				type: 'text',
				validation: { disabled: true },
				header: 'Delivery Details',
			},
			{
				fieldName: 'delivery_price',
				type: 'text',
				validation: { disabled: true },
			},
			{
				fieldName: 'payment_ref',
				label: "Circlesplit ID",
				type: 'text',
				validation: { disabled: true },
				header: 'Circlesplit Details',
			},
			// {
			// 	fieldName: 'paymentChannel_id',
			// 	type: 'text',
			// 	validation: { disabled: true },
			// },
			{
				fieldName: 'status',
				type: 'select',
				validation: {},
				header: 'Order Status & Update',
			},
			{
				fieldName: 'merchant_comment',
				type: 'textarea',
				validation: {},
				col: 'col-12',
			},
		],
	},
	merchant: {
		common: [
			{
				fieldName: 'logo',
				type: 'file',
				validation: { required: false },
				header: 'Business Details',
			},
			{
				fieldName: 'business_name',
				type: 'text',
				validation: { required: true },
			},
			{
				fieldName: 'business_email',
				type: 'email',
				validation: { required: true },
			},
			{
				fieldName: 'business_mobile',
				type: 'text',
				validation: { required: true, maxLength: 10 },
			},
			{
				fieldName: 'business_address',
				type: 'text',
				validation: { required: true },
			},
			{
				fieldName: 'business_type',
				type: 'select',
				validation: { required: true },
				endpoint: 'select-list/businesstypes',
			},
			{
				fieldName: 'country_id',
				type: 'select',
				validation: { required: true },
				dataSet: [
					{
						value: 'Ghana',
						id: 1,
					},
				],
			},
			{
				fieldName: 'business_reg',
				type: 'text',
				validation: { required: true },
			},
			{
				fieldName: 'status',
				type: 'select',
				validation: { required: true },
				dataSet: [
					{
						value: 'Active',
						id: 1,
					},
					{
						value: 'Inactive',
						id: 0,
					},
				],
			},
			// { fieldName: "plan_id", type: "text", validation: { required: true } },
			{
				fieldName: 'email_notify',
				type: 'select',
				validation: { required: true },
				dataSet: [
					{
						value: 'Allow',
						id: 1,
					},
					{
						value: 'Do not allow',
						id: 0,
					},
				],
			},
			{
				fieldName: 'push_notify',
				type: 'select',
				validation: { required: true },
				dataSet: [
					{
						value: 'Allow',
						id: 1,
					},
					{
						value: 'Do not allow',
						id: 0,
					},
				],
			},
			{
				fieldName: 'self_delivery',
				type: 'select',
				validation: { required: true },
				dataSet: [
					{
						value: 'Have my own delivery',
						id: 1,
					},
					{
						value: 'Do not have my own delivery',
						id: 0,
					},
				],
			},
			{
				fieldName: 'first_name',
				type: 'text',
				validation: { required: true },
				header: 'Contact Person Details',
			},
			{ fieldName: 'last_name', type: 'text', validation: { required: true } },
			{ fieldName: 'mobile', type: 'text', validation: { required: true } },
			{ fieldName: 'email', type: 'text', validation: { required: true } },
			{
				fieldName: 'password',
				type: 'password',
				validation: { required: true },
			},
		],
	},
};

export const camelCased = (text) => {
	if (!text) return '';

	if (text.endsWith('id')) text = text.substring(0, text.length - 2);

	return text
		?.split('_')
		?.map(
			(word) =>
				`${word.substring(0, 1).toLocaleUpperCase()}${word.substring(1)}`
		)
		?.join(' ');
};

export const processValue = (value) => {
	if (typeof value === 'object') return typeof value;

	return value;
};

export const productsImages = (images) => {
	return images
		?.filter((f, i) => i < 2)
		?.map(({ file_path }, i) => (
			<div
				style={{
					width: 60,
					height: 60,
					borderRadius: 60,
					float: 'left',
					overflow: 'hidden',
				}}>
				<img
					src={imgPath + file_path}
					width={60}
					crossOrigin='anonymous'
					style={{}}
				/>
			</div>
		));
};
