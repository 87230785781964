import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { imgPath } from '../../config';
import http from '../../http';
import { useToast } from 'use-toast-mui';
import { useNavigate } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
import { CiTrash, CiEdit } from 'react-icons/ci';
import ConfirmModal from '../../components/ConfirmModal';

const Categories = () => {
	const navigation = useNavigate();
	const toast = useToast();
	const [page, setPage] = useState(1);
	const [modal, setModal] = useState({});
	const [total, setTotal] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const getCategories = async () => {
		const queryData = await http.get(
			`products/product/categories?page=${page}&perPage=${perPage}`
		);
		setTotal(queryData?.data?.length);
		return queryData?.data;
	};
	const { data, isLoading, refetch } = useQuery({
		queryKey: ['categories', page],
		queryFn: () => getCategories(page),
	});

	const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page);
		setPerPage(newPerPage);
	};

	const mutation = useMutation(
		(id) => http.delete(`admin/product/categories/${id}`),
		{
			onSuccess: (response) => {
				toast.success(response?.data);
				setModal((prev) => ({ ...modal, visibililty: false }));
				refetch();
			},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
			onSettled: () => {},
		}
	);

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<h1>Categories</h1>
					<nav
						className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
						aria-label='breadcrumb'>
						<ol className='breadcrumb pt-0'>
							<li className='breadcrumb-item'>
								<a href='#'>Marketplace</a>
							</li>
							<li className='breadcrumb-item'>
								<a href='#'>Category</a>
							</li>
							<li className='breadcrumb-item active' aria-current='page'>
								<a onClick={() => navigation('/admin/category/new', {})}>
									Create Category
								</a>
							</li>
						</ol>
					</nav>
					<div className='separator mb-5'></div>
				</div>
			</div>

			<div className='row mb-4'>
				<div className='col-12 mb-4'>
					<div className='card'>
						<div className='card-body'>
							<DataGrid
								columns={[
									{
										name: 'IMAGE',
										selector: (row) => (
											<img
												src={imgPath + row?.image}
												width={60}
												crossOrigin='anonymous'
												alt='img'
											/>
										),
										sortable: true,
										width: '150px',
									},
									{
										name: 'LABEL',
										selector: (row) => row?.label,
										sortable: true,
									},
									{
										name: 'ITEMS',
										selector: (row) => row?.count,
										sortable: true,
									},
									{
										name: 'ACTIONS',
										selector: (row) => (
											<div>
												<CiEdit
													size='20px'
													className='mr-2'
													onClick={() =>
														navigation(`/admin/category/update/${row?.id}`, {
															state: row,
														})
													}
												/>
												<CiTrash
													size='20px'
													onClick={() =>
														setModal({
															visibililty: true,
															title: 'Are you sure?',
															description:
																'You are about to permanently delete this category.',
															customStyles: {},
															actions: {
																close: () =>
																	setModal((prev) => ({
																		...modal,
																		visibililty: false,
																	})),
																confirm: () => mutation.mutate(row?.id),
															},
														})
													}
												/>
											</div>
										),
										width: '100px',
									},
								]}
								data={data || []}
								progressPending={isLoading || false}
								onRowClicked={(row) => {}}
								paginationTotalRows={total}
								paginationDefaultPage={page}
								onChangeRowsPerPage={handlePerRowsChange}
								onChangePage={(page) => setPage(page)}
							/>
						</div>
					</div>
				</div>
			</div>
			{modal.visibililty && (
				<ConfirmModal
					open={modal.visibililty}
					title={modal.title}
					description={modal?.description}
					actions={modal?.actions}
					customStyles={modal?.customStyles || {}}
				/>
			)}
		</div>
	);
};
export default Categories;
