import React, { useState } from 'react';

const Config = () => {
	const [env, setEnv] = useState(
		sessionStorage.getItem("circclesplit-admin-url") || "dev"
	  );
	return (
		<>
			<div className='fixed-background'></div>
			<main style={{ marginLeft: 0, marginRight: 0 }}>
				<div className='container'>
					<div className='row h-100'>
						<div className='col-12 col-md-10 mx-auto my-auto'>
							<div className='card auth-card'>
								<div className='position-relative image-side '>
									<p className=' text-white h2'>Circlesplit Marketplace</p>

									<p className='white mb-0'>
										Please use your credentials to login.
										<br />
										If you are not a member, please
										<br />
										<br />
										<br />
										<a href='/' className='white'>
											<h3>Login</h3>
										</a>
									</p>
								</div>
								<div className='form-side'>
									<a href='#'>
										<img
											className='d-none d-xs-block'
											src='../logos/logo.png'
											width='60'
											alt='img'
										/>
										<img
											className='logo-mobile d-block d-xs-none'
											src='./logo/logo.png'
											width='60'
											alt='img'
										/>
									</a>
									<h6 className='mb-4 mt-4'>System Environment Configuration</h6>
									<form
										action='#'
										className='av-tooltip tooltip-label-right'>
										<div className='error-l-75 form-group'>
											<button
													className='btn btn-primary btn-lg btn-shadow'
												type='button'
												onClick={() => {
													sessionStorage.setItem("megastore-admin-url", "prod");
													setEnv(
													  sessionStorage.getItem("megastore-admin-url") ||
														"dev"
													);
												  }}>
													Production Environment
												</button>
										</div>
										<div className='form-group'>
										<button className='btn btn-danger btn-lg btn-shadow'
												type='button'
												onClick={() => {
													sessionStorage.setItem("megastore-admin-url", "dev");
													setEnv(
													  sessionStorage.getItem("megastore-admin-url") ||
														"dev"
													);
												  }}>
													Development Environment
												</button>
										</div>
										<div className='d-flex justify-content-between align-items-center'>
											<a href=''>Currently using : {env}</a>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};
export default Config;
