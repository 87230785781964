import {
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
	Navigate,
} from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import Login from './app/Login';
import Home from './app/Home';
import NoPage from './app/NoPage';
import { useSelector } from 'react-redux';
import Categories from './app/Category/Categories';
import AddCategory from './app/Category/AddCateogery';
import Logout from './app/Logout';
import Products from './app/Products/Products';
import AddProduct from './app/Products/AddProduct';
import Orders from './app/Orders/Orders';
import ViewOrder from './app/Orders/ViewOrder';
import Merchants from './app/Accounts/Merchants';
import AddMerchant from './app/Accounts/AddMerchant';
import Users from './app/Accounts/Users';
import Config from './app/Config';
// import Accounts from './app/Accounts';
// import Stock from './app/Stock';

function App() {
	return (
		<Router>
			<Routes>
				<Route
					path='/'
					element={
						<IsLoggedIn>
							<Login />
						</IsLoggedIn>
					}
				/>
				<Route
					path='/config'
					element={
						<IsLoggedIn>
							<Config />
						</IsLoggedIn>
					}
				/>
				<Route
					path='/admin'
					element={
						<RequireAuth>
							<Layout />
						</RequireAuth>
					}>
					<Route index element={<Home />} />
					<Route path='category'>
						<Route index element={<Categories />} />
						<Route path='new' element={<AddCategory />} />
						<Route path='update/:id' element={<AddCategory />} />
						<Route path='*' element={<NoPage />} />
					</Route>
					<Route path='products'>
						<Route index element={<Products />} />
						<Route path='new' element={<AddProduct />} />
						<Route path='update/:id' element={<AddProduct />} />
					</Route>
					<Route path='orders'>
						<Route index element={<Orders />} />
						<Route path='view/:id' element={<ViewOrder />} />
					</Route>
					<Route path='accounts'>
						<Route index element={<Merchants />} />
						<Route path='new' element={<AddMerchant />} />
						<Route path='update/:id' element={<AddMerchant />} />
					</Route>
					<Route path='users'>
						<Route index element={<Users />} />
						{/* <Route path="update/:id" element={<AddMerchant />} /> */}
					</Route>
				</Route>
				<Route path='/logout' element={<Logout />} />
				<Route path='*' element={<NoPage />} />
			</Routes>
		</Router>
	);
}

const RequireAuth = ({ children }) => {
	const { isLoggedIn } = useSelector((state) => state.user);
	const location = useLocation();
	if (!isLoggedIn) {
		return <Navigate to='/' state={{ from: location }} replace />;
	}

	return children;
};

const IsLoggedIn = ({ children }) => {
	const { isLoggedIn } = useSelector((state) => state.user);
	const location = useLocation();
	if (isLoggedIn) {
		return <Navigate to='/admin' state={{ from: location }} replace />;
	}

	return children;
};

export default App;
