import React, { useState, useEffect } from 'react';
import http from '../http';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
    const location = useLocation();
    const navigation = useNavigate();
    const [active, setActive] = useState('');
    const { user, accesses } = useSelector((state) => state.user);
    const accessesQuery = useQuery({
        queryKey: ['accesses'],
        queryFn: () => http.get('admin/accesses'),
        retry: 0,
        enabled: !accesses.length,
    });

    useEffect(() => {
        setActive(location?.pathname);
    }, [location]);

    return (
        <div className='menu' style={{ zIndex: 99 }}>
            <div className='main-menu'>
                <div className='scroll'>
                    <ul className='list-unstyled'>
                        {accessesQuery?.data?.data
                            ?.filter((f) => f.role_id === user?.role_id && f.parent_id === 1)
                            .map((access) => (
                                <li
                                    key={access?.featureData?.label}
                                    className={active === access?.endpoint ? 'active' : ''}>
                                    <a href="" onClick={() => navigation(access?.endpoint)}>
                                        <i
                                            className={
                                                access?.featureData?.icon ||
                                                'iconsminds-shopping-basket'
                                            }></i>
                                        <span>{access?.featureData?.label}</span>
                                    </a>
                                </li>
                            ))}
                    </ul>
                    {/* {(this.state.isAdmin === true) &&
                        <ul className="list-unstyled">
                            <li className="active">
                                <a href="/dashboard">
                                    <i className="iconsminds-shop-4"></i>
                                    <span>Dashboards</span>
                                </a>
                            </li>
                            <li>
                                <a href="#layouts">
                                    <i className="iconsminds-digital-drawing"></i> Accounts</a>
                            </li>
                            <li>
                                <a href="#layouts">
                                    <i className="iconsminds-digital-drawing"></i> Products</a>
                            </li>
                            <li>
                                <a href="#layouts">
                                    <i className="iconsminds-digital-drawing"></i> Category</a>
                            </li>
                            <li>
                                <a href="#layouts">
                                    <i className="iconsminds-digital-drawing"></i> Add-Ons</a>
                            </li>
                            <li>
                                <a href="#applications">
                                    <i className="iconsminds-air-balloon-1"></i> Sales</a>
                            </li>
                            <li>
                                <a href="#ui">
                                    <i className="iconsminds-pantone"></i> Cash-Out</a>
                            </li>

                        </ul>
                    }
                    {(this.state.isAdmin === false) &&
                        <ul className="list-unstyled">
                            <li className="active">
                                <a href="#dashboard">
                                    <i className="iconsminds-shop-4"></i>
                                    <span>Dashboards</span>
                                </a>
                            </li>
                            <li>
                                <a href="#mprofile">
                                    <i className="iconsminds-male-female"></i> My Account</a>
                            </li>
                            <li>
                                <a href="#mproducts">
                                    <i className="iconsminds-digital-drawing"></i> Products</a>
                            </li>
                            <li>
                                <a href="#mextra">
                                    <i className="iconsminds-digital-drawing"></i> Extras
                                </a>
                            </li>
                            <li>
                                <a href="#msales">
                                    <i className="iconsminds-shopping-basket"></i> Sales</a>
                            </li>
                            <li>
                                <a href="#mdelivery">
                                    <i className="iconsminds-shopping-cart"></i> Delivery</a>
                            </li>
                            <li>
                                <a href="/cashout">
                                    <i className="iconsminds-coins"></i> Cash-Out</a>
                            </li>
                            <li>
                                <a href="settings">
                                    <i className="iconsminds-gears"></i> Settings</a>
                            </li>

                        </ul>
                    } */}
                </div>
            </div>

            {/* <div className="sub-menu">
                <div className="scroll">
                    <ul className="list-unstyled" data-link="mprofile">
                        <li>
                            <a href="/businessdetails">
                                <i className="simple-icon-briefcase"></i> <span className="d-inline-block">Business Details</span>
                            </a>
                        </li>
                        <li>
                            <a href="/paymentMethod">
                                <i className="simple-icon-credit-card"></i> <span className="d-inline-block">Payment Methods</span>
                            </a>
                        </li>
                        <li>
                            <a href="/passwordreset">
                                <i className="simple-icon-settings"></i> <span className="d-inline-block">Password Reset</span>
                            </a>
                        </li>
                        <li>
                            <a href="#" onClick={() => this.logout()}>
                                <i className="simple-icon-power"></i> <span className="d-inline-block">Logout</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="list-unstyled" data-link="mproducts">
                        <li>
                            <a href="/addproduct">
                                <i className="simple-icon-plus"></i> <span className="d-inline-block">Add Product</span>
                            </a>
                        </li>
                        <li>
                            <a href="/products">
                                <i className="simple-icon-pie-chart"></i> <span className="d-inline-block">View Promotions</span>
                            </a>
                        </li>
                        <li>
                            <a href="/products">
                                <i className="simple-icon-grid"></i> <span className="d-inline-block">View Products</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="list-unstyled" data-link="mextra">
                        <li>
                            <a href="/addextra">
                                <i className="simple-icon-plus"></i> <span className="d-inline-block">Add An Extra</span>
                            </a>
                        </li>
                        <li>
                            <a href="/extras">
                                <i className="simple-icon-pie-chart"></i> <span className="d-inline-block">View Extra</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="list-unstyled" data-link="msales">
                        <li>
                            <a href="/openorders">
                                <i className="iconsminds-shopping-basket"></i> <span className="d-inline-block">Open Orders</span>
                            </a>
                        </li>
                        <li>
                            <a href="/orders">
                                <i className="iconsminds-checkout-bag"></i> <span className="d-inline-block">Completed Orders</span>
                            </a>
                        </li>
                        <li>
                            <a href="/failedOrder">
                                <i className="iconsminds-checkout-bag"></i> <span className="d-inline-block">Failed Orders</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="list-unstyled" data-link="mdelivery">
                        <li>
                            <a href="/createDelivery">
                                <i className="iconsminds-add-cart"></i> <span className="d-inline-block">Create Delivery Option</span>
                            </a>
                        </li>
                        <li>
                            <a href="/delivery">
                                <i className="iconsminds-checkout"></i> <span className="d-inline-block">View Delivery Option</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div> */}
        </div>
    );
}

export default Sidebar;
