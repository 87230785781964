import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useToast } from 'use-toast-mui';
import { useNavigate } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
import { CiTrash, CiEdit } from 'react-icons/ci';
import { productsImages } from '../../util';
import ConfirmModal from '../../components/ConfirmModal';
import http from '../../http';

const Products = () => {
	const navigation = useNavigate();
	const [filters, setFilters] = useState({});
	const toast = useToast();
	const [page, setPage] = useState(1);
	const [modal, setModal] = useState({});
	const [total, setTotal] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const getProducts = async () => {
		const queryData = await http.get(
			`products/product?page=${page}&perPage=${perPage}&${Object.keys(filters)?.map(m=>`${m}=${filters[m]}`).join("&")}`
		);
		setTotal(queryData?.data?.count);
		return queryData?.data?.rows;
	};
	const { data, isLoading, refetch } = useQuery({
		queryKey: ['products', page, perPage],
		queryFn: () => getProducts(page),
		keepPreviousData: true,
	});

	const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page);
		setPerPage(newPerPage);
	};

	const mutation = useMutation((id) => http.delete(`products/product/${id}`), {
		onSuccess: (response) => {
			toast.success(response?.data);
			setModal((prev) => ({ ...modal, visibililty: false }));
			refetch();
		},
		onError: (err) => {
			toast.error(
				err?.response?.data?.message || err?.response?.data || err?.message
			);
		},
		onSettled: () => {},
	});

	const merchantList = useQuery(['getMerchantList'], () => http.get('admin/merchants'));

	useEffect(() => {
		getProducts();
	}, [filters]);

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<h1>Products</h1>
					<nav
						className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
						aria-label='breadcrumb'>
						<ol className='breadcrumb pt-0'>
							<li className='breadcrumb-item'>
								<a href='#'>Marketplace</a>
							</li>
							<li className='breadcrumb-item'>
								<a href='#'>Products</a>
							</li>
							<li className='breadcrumb-item active' aria-current='page'>
								<a onClick={() => navigation('/admin/products/new', {})}>
									Create Products
								</a>
							</li>
						</ol>
					</nav>
					<div className='separator mb-5'></div>
				</div>
				<div className='col-12'>
					<div className='row mb-3'>
						<div className='col-auto'>
							<nav
							className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
							aria-label='breadcrumb'>
								<ol className='breadcrumb pt-0'>
									<li className='breadcrumb-item'>
										<a href='#'>Filters</a>
									</li>
								</ol>
							</nav>
						</div>
						<div className='col-auto'>
							<select className="form-control" onChange={(e) => setFilters(prev => ({ ...prev, merchant_id: e.target.value }))}>
								<option value="">Select Merchant</option>
								{(merchantList?.data?.data?.rows || [])?.map(m => <option value={m?.merchant_id}>{m?.business_name}</option>)}
							</select>
						</div>
						<div className='col-auto'>
							<select className="form-control" onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}>
								<option value="0">Inactive</option>
								<option value="1">Active</option>
							</select>
						</div>
					</div>
				</div>
			</div>

			<div className='row mb-4'>
				<div className='col-12 mb-4'>
					<div className='card'>
						<div className='card-body'>
							<DataGrid
								columns={[
									{
										name: 'IMAGE',
										selector: (row) => (
											<>
												{productsImages(row?.ProductImages)}
												{row?.ProductImages?.length - 2 > 0 && (
													<span className='position-absolute badge-top-left badge badge-secondary badge-pill'>
														{row?.ProductImages?.length} images
													</span>
												)}
											</>
										),
										sortable: true,
										width: '150px',
									},
									{
										name: 'LABEL',
										selector: (row) => row?.name,
										sortable: true,
									},
									{
										name: 'QUANTITY',
										selector: (row) =>
											Number(row?.quantity) ? row?.quantity : 'Unlimited',
										sortable: true,
									},
									{
										name: 'PRICE',
										selector: (row) => `GHS ${row?.cost_price}`,
										sortable: true,
									},
									{
										name: 'S.PRICE',
										selector: (row) => `GHS ${row?.selling_price}`,
										sortable: true,
									},
									{
										name: 'CATEGORY',
										selector: (row) => row?.ProductCategory?.label,
										sortable: true,
									},
									{
										name: 'MERCHANT',
										selector: (row) => row?.Merchant?.business_name,
										sortable: true,
									},
									{
										name: 'STATUS',
										selector: (row) =>
											row?.status ? 'Published' : 'Unpublished',
										sortable: true,
									},
									{
										name: 'ACTIONS',
										selector: (row) => (
											<div>
												<CiEdit
													size='20px'
													className='mr-2'
													onClick={() =>
														navigation(`/admin/products/update/${row?.id}`, {
															state: row,
														})
													}
												/>
												<CiTrash
													size='20px'
													onClick={() =>
														setModal({
															visibililty: true,
															title: 'Are you sure?',
															description:
																'You are about to permanently delete this products.',
															customStyles: {},
															actions: {
																close: () =>
																	setModal((prev) => ({
																		...modal,
																		visibililty: false,
																	})),
																confirm: () => {
																	setModal((prev) => ({
																		...modal,
																		visibililty: false,
																	}));
																	mutation.mutate(row?.product_id);
																},
															},
														})
													}
												/>
											</div>
										),
										width: '100px',
									},
								]}
								data={data || []}
								progressPending={isLoading || false}
								pagination
								paginationServer
								paginationTotalRows={total}
								paginationDefaultPage={page}
								onChangeRowsPerPage={handlePerRowsChange}
								onChangePage={(page) => setPage(page)}
							/>
						</div>
					</div>
				</div>
			</div>
			{modal.visibililty && (
				<ConfirmModal
					open={modal.visibililty}
					title={modal.title}
					description={modal?.description}
					actions={modal?.actions}
					customStyles={modal?.customStyles || {}}
				/>
			)}
		</div>
	);
};
export default Products;
