import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { imgPath } from '../../config';
import http from '../../http';
import { useToast } from 'use-toast-mui';
import { useNavigate } from 'react-router-dom';
import DataGrid from '../../components/DataGrid';
import { CiRead } from 'react-icons/ci';
import ConfirmModal from '../../components/ConfirmModal';
import moment from 'moment';

const Orders = () => {
	const navigation = useNavigate();
	const toast = useToast();
	const [page, setPage] = useState(1);
	const [modal, setModal] = useState({});
	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const getOrders = async (pageFetch = 1) => {
		const queryData = await http.get(
			`orders/orders?page=${pageFetch}&perPage=${perPage}`
		);
		setTotal(queryData?.data?.count);
		setItems(queryData?.data?.orderProducts);
		return queryData?.data?.orders;
	};
	const { data, isLoading, refetch } = useQuery({
		queryKey: ['orders', page, perPage],
		queryFn: () => getOrders(page),
	});

	const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page);
		setPerPage(newPerPage);
	};

	const handlePageChange = (page) => {
		setPage(page);
	};

	useMutation((id) => http.delete(`admin/product/categories/${id}`), {
		onSuccess: (response) => {
			toast.success(response?.data);
			setModal((prev) => ({ ...modal, visibililty: false }));
			refetch();
		},
		onError: (err) => {
			toast.error(
				err?.response?.data?.message || err?.response?.data || err?.message
			);
		},
		onSettled: () => { },
	});

	const getOrderItems = (data) => {
		return data?.Items?.map((m) => {
			return {
				...m,
				product: items?.find((f) => f.product_id === m.product_id),
			};
		})?.map((m) => {
			return (
				<div className='float-left'>
					{m?.product?.ProductImages?.map((img) => (
						<div
							className='m-1'
							style={{ borderRadius: 60, overflow: 'hidden' }}>
							<img
								src={imgPath + img?.file_path}
								crossOrigin='anonymous'
								width='40'
								alt='img'
							/>
						</div>
					))}
				</div>
			);
		});
	};

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<h1>Categories</h1>
					<nav
						className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
						aria-label='breadcrumb'>
						<ol className='breadcrumb pt-0'>
							<li className='breadcrumb-item'>
								<a href='#'>Marketplace</a>
							</li>
							<li className='breadcrumb-item'>
								<a href='#'>Orders</a>
							</li>
						</ol>
					</nav>
					<div className='separator mb-5'></div>
				</div>
			</div>

			<div className='row mb-4'>
				<div className='col-12 mb-4'>
					<div className='card'>
						<div className='card-body'>
							<DataGrid
								columns={[
									{
										name: 'ITEMS',
										selector: (row) => getOrderItems(row),
										sortable: true,
									},
									{
										name: 'ORDER ID',
										selector: (row) => row?.order_id,
										sortable: true,
										width: '160px',
									},
									{
										name: 'MERCHANT',
										selector: (row) => row?.Merchant?.business_name,
										sortable: true,
										width: '160px',
									},
									{
										name: 'TOTAL',
										selector: (row) => `GHS ${row?.total}`,
										sortable: true,
										width: '120px',
									},
									{
										name: 'DEL. PRICE',
										selector: (row) => `GHS ${row?.total}`,
										sortable: true,
										width: '120px',
									},
									{
										name: 'DELIVERY',
										selector: (row) => row?.delivery_options_id,
										sortable: true,
										width: '120px',
									},
									{
										name: 'TIMESTAMP',
										selector: (row) =>
											moment(row?.order_timestamp).format('Do MMM,YYYY HH:mm'),
										sortable: true,
										width: '140px',
									},
									{
										name: 'STATUS',
										selector: (row) => row?.orderStatus?.label,
										sortable: true,
										width: '80px',
									},
									{
										name: 'ACTIONS',
										selector: (row) => (
											<div>
												<CiRead
													size='20px'
													className='mr-2'
													onClick={() =>
														navigation(`/admin/orders/view/${row?.id}`, {
															state: row,
														})
													}
												/>
											</div>
										),
										width: '80px',
									},
								]}
								data={data || []}
								progressPending={isLoading || false}
								pagination
								paginationServer
								paginationTotalRows={total}
								paginationDefaultPage={page}
								onChangeRowsPerPage={handlePerRowsChange}
								onChangePage={handlePageChange}
							/>
						</div>
					</div>
				</div>
			</div>
			{modal.visibililty && (
				<ConfirmModal
					open={modal.visibililty}
					title={modal.title}
					description={modal?.description}
					actions={modal?.actions}
					customStyles={modal?.customStyles || {}}
				/>
			)}
		</div>
	);
};
export default Orders;
