import React from 'react';
import { useForm } from 'react-hook-form';
import Spinner from '../components/Spinner';
import http from '../http';
import { useToast } from 'use-toast-mui';
import { useDispatch } from 'react-redux';
import { setDynamicData } from '../redux/userSlice';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

const Login = () => {
	const {
		register,

		handleSubmit,
		getValues,
		formState: { errors, isDirty, isSubmitting },
	} = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
		mode: 'onChange',
	});
	const toast = useToast();
	const dispatch = useDispatch();
	const navigation = useNavigate();

	const loginCall = useMutation(['loginCall'], () => http.post('admin/login', getValues()), {
		onSuccess: (result) => {
			dispatch(setDynamicData({ key: 'user', value: result?.data?.user }));
			dispatch(setDynamicData({ key: 'isLoggedIn', value: true }));
			localStorage.setItem('token', result?.data?.token);
			localStorage.setItem('refreshToken', result?.data?.refreshToken);
			navigation('admin', {});
		},
		onError: (err) => {
			toast.error(err?.response?.data || err.message);
		}
	})

	const onSubmit = (payload) => loginCall.mutate();

	return (
		<>
			<div className='fixed-background'></div>
			<main style={{ marginLeft: 0, marginRight: 0 }}>
				<div className='container'>
					<div className='row h-100'>
						<div className='col-12 col-md-10 mx-auto my-auto'>
							<div className='card auth-card'>
								<div className='position-relative image-side '>
									<p className=' text-white h2'>Circlesplit Marketplace</p>

									<p className='white mb-0'>
										Please use your credentials to login.
										<br />
										If you are not a member, please
										<br />
										<br />
										<br />
										<a href='/register' className='white'>
											<h3>Create Account</h3>
										</a>
									</p>
								</div>
								<div className='form-side'>
									<a href='#'>
										<img
											className='d-none d-xs-block'
											src='../logos/logo.png'
											width='60'
											alt='img'
										/>
										<img
											className='logo-mobile d-block d-xs-none'
											src='./logo/logo.png'
											width='60'
											alt='img'
										/>
									</a>
									<h6 className='mb-4 mt-4'>Login</h6>
									<form
										action='#'
										className='av-tooltip tooltip-label-right'
										onSubmit={handleSubmit(onSubmit)}>
										<div className='error-l-75 form-group'>
											<label className=''>Mobile Number / Email</label>
											<input
												name='lastName'
												className='form-control'
												{...register('username', { required: true })}
											/>
											{errors?.username && (
												<div className='invalid-feedback d-block'>
													{errors?.username?.message ||
														'Mobile number or email address is required'}
												</div>
											)}
										</div>
										<div className='form-group'>
											<label className=''>Password</label>
											<input
												name='password'
												className='form-control'
												type='password'
												{...register('password', {
													required: true,
													minLength: 8,
												})}
											/>
											{errors?.password && (
												<div className='invalid-feedback d-block'>
													{errors?.password?.message || 'Password is required'}
												</div>
											)}
										</div>
										<div className='d-flex justify-content-between align-items-center'>
											<a href='/recovery'>Forget password?</a>
											{!loginCall.isLoading ? (
												<button
													className='btn btn-primary btn-lg btn-shadow'
													type='submit'
													disabled={!isDirty && !isDirty}>
													LOGIN
												</button>
											) : (
												<Spinner bg='#ffffff' fill='##001e31' />
											)}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};
export default Login;
