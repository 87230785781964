import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import http from '../../http';
import { useToast } from 'use-toast-mui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { forms } from '../../util';
import Forms from '../../components/Forms';

const AddCategory = () => {
	const toast = useToast();
	const location = useLocation();
	const navigation = useNavigate();
	let { id } = useParams();

	const form = useForm({
		defaultValues: {},
		mode: 'all',
	});
	const queryClient = useQueryClient();

	const mutation = useMutation(
		(payload) =>
			id
				? http.put(`admin/product/categories/${id}`, payload)
				: http.post(`admin/product/categories`, payload),
		{
			onSuccess: (response) => {
				toast.success(response?.data);
				navigation('/admin/category', {});
			},
			onError: (err) => {
				toast.error(
					err?.response?.data?.message || err?.response?.data || err?.message
				);
			},
			onSettled: () => {
				queryClient.invalidateQueries('create');
			},
		}
	);

	const actions = {
		onSubmit: (data) => {
			const payload = new FormData();
			data?.createCategory?.map((value) => {
				const obj = Object.entries(value)[0];
				if (obj[1]) {
					if (typeof obj[1] === 'object') {
						if (obj[1][0]) payload.append(obj[0], obj[1][0], obj[0]);
					} else payload.append(obj[0], obj[1]);
				}
			});

			mutation.mutate(payload);
		},
	};

	useEffect(() => {
		['label']?.map((keys, index) => {
			form.setValue(`createCategory.${index}.${keys}`, location?.state?.[keys]);
		});
	}, [location]);

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<h1>{id ? 'Update' : 'Add'} Categories</h1>
					<nav
						className='breadcrumb-container d-none d-sm-block d-lg-inline-block'
						aria-label='breadcrumb'>
						<ol className='breadcrumb pt-0'>
							<li className='breadcrumb-item'>
								<a href='#'>Marketplace</a>
							</li>
							<li className='breadcrumb-item'>
								<a onClick={() => navigation('/admin/category', {})}>
									Categories
								</a>
							</li>

							{id ? (
								<li className='breadcrumb-item active' aria-current='page'>
									#{id}
								</li>
							) : (
								<li className='breadcrumb-item active' aria-current='page'>
									New Record
								</li>
							)}
						</ol>
					</nav>
					<div className='separator mb-5'></div>
				</div>
			</div>

			<div className='row mb-4'>
				<div className='col-12 mb-4'>
					<div className='card'>
						<div className='card-body'>
							<h6 className='mb-4'>
								{id ? 'Update' : 'Add New'} Category {id ? `#${id}` : ''}
							</h6>
							<Forms
								form={form}
								formName='createCategory'
								fields={[...forms.category.common]}
								actions={actions}
								submit={id ? 'Update Category' : 'Create Category'}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AddCategory;
