import axios from 'axios';
import baseURL from './config';

const api = baseURL;
const http = axios.create({
	baseURL: api,
});

http.interceptors.request.use(
	async (config) => {
		const token = localStorage.getItem('token') || null;
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

http.interceptors.response.use(
	async (response) => {
		return response;
	},
	async (error) => {
		if (error?.response?.status === 401) {
			const originalRequest = error.config;
			localStorage.removeItem('token');
			originalRequest._retry = true;

			const access_token = await axios.post(baseURL + '/admin/refresh', {
				refresh: localStorage.getItem('refreshToken'),
			});
			if (access_token?.status === 200) {
				console.log(access_token?.data);
				localStorage.setItem('token', access_token?.data);
				originalRequest.headers.Authorization = access_token?.data;
			}
			return http(originalRequest);
		}
		return Promise.reject(error);
	}
);

export default http;
